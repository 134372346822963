import Layout from "../components/Layout/Layout"
import Page from "../components/Page/Page"
import { fetchFrontPageStatic } from "../lib/queries"
import { PageResult } from "../lib/types"

const IndexPage = () => {
  const data: PageResult = fetchFrontPageStatic()
  return (
    <Layout
      showHeader={!!data.pageComponents.showheader}
      footerMenu={data.menus.footer}
      contact={data.settings.contact}
    >
      <Page {...data} />
    </Layout>
  )
}

export default IndexPage
