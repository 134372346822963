import { graphql, useStaticQuery } from "gatsby"
import { PageResult } from "./types"

export const pageDataFragment = graphql`
  fragment PageData on WpPage {
    id
    slug
    title
    isFrontPage
    pageComponents {
      fieldGroupName
      fullpagescroll
      seoDescription
      showheader
      modules {
        ... on WpPage_Pagecomponents_Modules_HomeIntro {
          fieldGroupName
          preHeadline
          headline
          subheadline
          blocks {
            icons {
              icon
            }
            headline
            fieldGroupName
            bg {
              altText
              localFile {
                childImageSharp {
                  fixed(
                    width: 500
                    duotone: { highlight: "#454f20", shadow: "#1e1c23" }
                    webpQuality: 100
                    quality: 100
                  ) {
                    src
                    srcWebp
                  }
                }
              }
            }
          }
          anchor
        }
        ... on WpPage_Pagecomponents_Modules_TextPhoto {
          headline
          fieldGroupName
          blocks {
            headline
            text
          }
          image {
            altText
            localFile {
              childImageSharp {
                fixed(
                  width: 500
                  duotone: { highlight: "#DFDFDF", shadow: "#1e1c23" }
                  webpQuality: 100
                  quality: 100
                ) {
                  src
                  srcWebp
                }
              }
            }
          }
        }
        ... on WpPage_Pagecomponents_Modules_SlicedContent {
          fieldGroupName
          headline
          content {
            content
            headline
          }
          anchor
        }
        ... on WpPage_Pagecomponents_Modules_HeaderCommon {
          fieldGroupName
          headline
          subheadline
        }
        ... on WpPage_Pagecomponents_Modules_EditorText {
          fieldGroupName
          text
        }
      }
    }
  }
`

export const menuFragment = graphql`
  fragment Menu on WpMenu {
    id
    name
    slug
    menuItems {
      nodes {
        title
        url
        connectedNode {
          node {
            ... on WpPage {
              id
              title
            }
          }
        }
      }
    }
  }
`


const frontPageQuery = graphql`
  query FrontPageQuery {
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          ...PageData
        }
      }
    }
    wpMenu(slug: { eq: "footer" }) {
      ...Menu
    }
    allWp {
      nodes {
        acfOptionsOptionsPage {
          settings {
            contact {
              mail
              tel
            }
          }
        }
      }
    }
  }
`

export const fetchFrontPageStatic = (): PageResult => {
  const query = useStaticQuery(frontPageQuery)
  const { node } = query.allWpPage.edges[0]
  const menus = { footer: query.wpMenu }
  const { settings } = query.allWp.nodes[0].acfOptionsOptionsPage
  return { ...node, menus, settings }
}
